$black: #1d1d1d;
$dark-gray: #3e3e3e;
$gray: #575b60;
$navBar: #0c3d5b;
$navBarText: white;
$userBar: darken($navBar, 2.5%);
$primary: #0c3d5b;
$primary-light: #0e4669;
$anlLightGrey: #f8fbfc;
$anlMediumGrey: #e4eaf1;
$anlDarkGrey: #e9eff4;
$green: #28a745 !default;

$anl-100: #ebf4ff;
$anl-200: #7dace4;
$anl-300: #3373c0;
$anl-400: #285b98;
$anl-500: #184070;

.border-primary {
  border-color: $primary !important;
}

main{
--companyBg: #{$primary};
}
