.custom-checkbox {
  input[type="checkbox"]:checked + .box:after {
    background-color: $primary;
    border-color: $primary;
  }
}

.dropdown-item:active {
  background-color: $primary;
  color: $white
}
