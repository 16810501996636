nav.navbar.gc_theme {
  $textColor: lighten($navBar, 70%);
  $hoverTextColor: lighten($navBar, 40%);
  &.navbar-dark {
    background-color: $navBar !important;
  }

  .truck {
    text-shadow: -1px 0 #000, 0 1px $navBar, 1px 0 $navBar, 0 -1px $navBar;
  }

  .dropdown-toggle,
  .topItem {
    &.iconWithBadge:before {
      color: #fff;
    }
  }

  .topItem {
    color: $navBarText;
  }

  #menuButton {
    color: $textColor;

    &:hover {
      color: $hoverTextColor !important;
    }
  }

  .userBar {
    background-color: $userBar;

    #parent_usersettings:before {
      color: white
    }

    .topItem {
      color: $navBarText;

      &:hover {
        color: $hoverTextColor !important;
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }
}

.body:not(.dark-theme) {
  $menuBg: #11345f;
  app-mobile-navigation {
    background-color: $menuBg;
    li,
    app-article-groups-browser {
      background-color: $menuBg;
    }
    h3:not(.hasLink) {
      color: lighten($navBar, 40%);
    }
  }
}

.body.dark-theme {
  $menuBg: var(--primaryBg);
  app-mobile-navigation {
    background-color: $menuBg;
    li,
    app-article-groups-browser {
      background-color: $menuBg;
    }
    h3:not(.hasLink) {
      color: lighten($navBar, 40%);
    }
  }
}

@media (max-width: 360px) {
  .logo {
    min-width: 85px;
    margin-left: 10px;
  }
}

app-catalogue-navbar {
  --catButtonHoverBg: #{ $navBar};
}
