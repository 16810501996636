.btn {
  &.btn-primary {
    background-color: $primary;
    border-color: $mediumGrey;
  }
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;
}

$btn-color: #002169;
.btn {
  &.btn-success {
    background-color: $btn-color;
    border-color: $btn-color;

    &:focus, &:active {
      background-color: darken($btn-color, 5%);
      box-shadow: 0 0 0 0.2rem transparentize($btn-color, 0.5)!important;
      border-color: darken($btn-color, 5%);
    }
  }
}
.quickAdd.bg-success {
  background-color: $btn-color!important;
  border-color: $btn-color;
  &:focus, &:active {
    background-color: darken($btn-color, 5%);
    box-shadow: 0 0 0 0.2rem transparentize($btn-color, 0.5)!important;
    border-color: darken($btn-color, 5%);
  }
}
