nav.navbar {
  $textColor: lighten($navBar, 70%);
  $hoverTextColor: lighten($navBar, 40%);
  &.navbar-dark {
    background-color: $black !important;
  }

  .truck {
    text-shadow: -1px 0 #000, 0 1px  $navBar, 1px 0 $navBar, 0 -1px $navBar;
  }

  .dropdown-toggle, .topItem {
    &.iconWithBadge:before {
      color: #FFF;
      background-color: $primary;
    }
  }

  .topItem {
    color: lighten($navBar, 70%) !important;
  }

  #menuButton {
    color: $textColor;

    &:hover {
      color: $hoverTextColor!important
    }
  }

  .userBar {
    background-color: darken($darkGrey, 5%) !important;

    .topItem {
      color: $textColor;

      &:hover {
        color: $hoverTextColor!important
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }
}

@media (max-width: 360px) {
  .logo {
    min-width: 85px;
    margin-left: 10px;
  }
}
