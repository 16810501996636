ul.light {
  li {
    border-color: var(--secondaryBg);
    line-height: 30px;

    &:not(.active):hover {
      background-color: #EEE;
    }

    i.material-icons {
      transform: translateY(5px);
    }
  }
}
