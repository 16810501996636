button.btn,
div.btn,
span.btn,
a.btn,
.custom-btn,
.statusIcon,
input.btn {
  border-radius: $border-radius * 2 !important;
  &.rounded {
    border-radius: 20px !important;
  }

  &.btn-default {
    background-color: var(--secondaryBg);
  }
}

.btn.loading {
  position: relative;
  overflow: hidden;
  span {
    position: relative;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: repeating-linear-gradient(60deg,
                transparent,
                transparent 0.75rem,
                rgba(0,0,0,0.5) 0.75rem,
                rgba(0,0,0,0.4) 1.5rem);
    animation: load 1s infinite linear;
  }

  /* Loading button animation */
  @keyframes load {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1.75rem);
    }
  }
}

.fab {
  position: fixed;
  right: 5vw;
  z-index: 9;
  bottom: 120px;
}

.fixedButtonsContainer {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  min-width: 480px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1030;
  text-align: center;
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;
}

.btn {
  &.btn-primary,
  &.btn-dark,
  &.btn-success {
    &:not(.disabled) {
      box-shadow: 0px 5px 10px rgb(0 0 0 / 17.5%);
      &:focus,
      &.selected {
        box-shadow: none;
      }
      &.selected {
        transform: scale(0.9);
      }
    }
  }
}


.quickAdd {
  box-shadow: 0px 5px 10px rgb(0 0 0 / 17.5%);
  &:focus,
  &.selected {
    box-shadow: none;
  }
}

.swiper-button-next {
  display: none
}


