$lightGreen: rgb(0, 194, 0);
$green: rgb(0, 194, 0);
$orange: rgb(252, 157, 69);
$lightRed: rgb(241, 58, 58);
$darkRed: rgb(233, 19, 19);
$purple: rgb(154, 124, 245);
$blue: rgb(49, 102, 192);

:root {
  --status012BgColor: #e6f2e6;
  --status3Color: rgb(255, 249, 246);
  --statusTextColor: $gray-800;
  --defaultStatusBorder: #def4de;
  --status012Color: #1c6a1c;
}

.status_text {
  &.status_3 {
    color: darken($orange, 10%)
  }
  &.status_4, &.status_5 {
    color: $lightRed
  }
}

.statusIcon {
  border: solid 1px var(--secondaryBg);
  color: var(--statusTextColor);
  font-size: 0.9rem;
  line-height: 1.5rem;

  &.articleRowLabel {
    max-height: 30px;
  }

  &:not(.articleRowLabel) {
    &:not(.notice) {
    max-height: 60px;
    }
  }

  &.status_0,
  &.status_1,
  &.status_2 {
    color: var(--status012Color);
    background-color: var(--status012BgColor);
  }
  &.status_3 {
    background-color: var(--status3Color);
    border-color: var(--status3Color);
  }
}

@include media-breakpoint-down(md) {
  .articleRowLabel {
    font-size: 0.8rem;
  }
}

button.articleRowLabel {
  height: 30px;
  margin-bottom: 2px;
  box-shadow: 0px 0px 1px 1px var(--secondaryBg)!important;
}

.statusIcon,
.articleRowLabel {
  display: inline-block;
  padding: 3px 5px;
  margin-right: 3px;
  transition: background linear 300ms;
  position: relative;
  color: var(--primaryTextColor);

  &:after {
    font-family: 'Material Icons';
    color: #666;
    margin-left: 3px;
    margin-right: 3px;
  }

  &:hover {
    color: #FFF;
  }

  &.selected,
  &:hover,
  &:focus {
    color: #FFF !important;
    &:after {
      color: #FFF !important;
    }
  }


  &.status_0:not(.stockweek_99) {

    //  background-color: rgb(243, 255, 243);
    border: solid 1px transparent;

    &.statusIcon {
      border-color: transparent;
    }

    &:after {
      color: $green;
      content: 'error_outline';
      top: 5px;
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $green !important;
      border-color: $green !important
    }
  }

  &.status_0.stockweek_99 {
    border-bottom-color: $lightRed;

    &.selected,
    &:hover,
    &:focus {
      background-color: $lightRed !important;
      border-color: $lightRed !important;
    }

    &:after {
      color: $lightRed;
      content: 'close';
    }
  }



  &.status_1 {
    border: solid 1px transparentize($color:  $green, $amount: 0.6);

    &:not(.selected) {
      background-color: transparentize($color:  $green, $amount: 0.9)!important;
    }

    &.statusIcon {
      border-color: var(--defaultStatusBorder);
    }

    &:after {
      color: $lightGreen;
      content: 'done_outline';
      top: 5px;
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $green !important;
      border-color: $green !important
    }
  }

  &.status_2 {

    &:not(.selected) {
      background-color: transparentize($color:  $green, $amount: 0.9)!important;
    }
    border-color:transparentize($color:  $green, $amount: 0.6);

    &.statusIcon.btn {
      border-color: var(--defaultStatusBorder);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $green !important;
      border-color: $green !important;
    }

    &:after {
      color: $green;
      content: 'local_shipping';
    }
  }

  &.status_3 {

    &:not(.selected) {
      background-color: transparentize($color:  $orange, $amount: 0.95)!important;
    }

    &.btn {
      border-color: transparentize($color:  $orange, $amount: 0.6);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $orange !important;
      border-color: $orange !important;
    }

    &:after {
      color: $orange;
      content: 'calendar_today';
    }
  }

  &.status_4 {

    &:not(.selected) {
      background-color: transparentize($color:  $red, $amount: 0.925)!important;
    }

    &.btn {
      border-color: transparentize($color:  $lightRed, $amount: 0.6);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $lightRed !important;
      border-color: $lightRed !important;
    }

    &:after {
      color: $lightRed;
      content: 'close';
    }
  }

  &.status_5 {
    &:not(.selected) {
      background-color: transparentize($color:  $red, $amount: 0.925)!important;
    }

    &.btn {
      border-color: transparentize($color:  $lightRed, $amount: 0.6);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $lightRed !important;
      border-color: $lightRed !important;
    }

    &:after {
      color: $lightRed;
      content: 'close';
    }
  }

  &.status_6 {
    &:not(.selected) {
      background-color: transparentize($color:  $purple, $amount: 0.9)!important;
    }

    &.btn {
      border-color: transparentize($color:  $purple, $amount: 0.6);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $purple !important;
      border-color: $purple !important;
    }

    &:after {
      color: $purple;
      content: 'offline_pin';
    }
  }

  &.status_7 {
    &:not(.selected) {
      background-color: transparentize($color:  $blue, $amount: 0.9)!important;
    }

    &.btn {
      border-color: transparentize($color:  $blue, $amount: 0.6);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: $blue !important;
      border-color: $blue !important;
    }

    &:after {
      color: $blue;
      content: 'help';
    }
  }
}


// TEMP hide stock winora!
// .WG {
//   .articleRowLabel.company_101 i, .articleRowLabel.company_101:after, .status.company_101, app-select-week,.status_text {
//     display: none;
//     visibility: hidden;
//     pointer-events: none;
//     &.company_101 {
//       display: none;
//       visibility: hidden;
//     }
//   }
//   .articleRowLabel {
//     &.company_101 {
//       border: none
//     }
//   }
// }
