.btn {
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;
}

.btn {
  &.btn-success {
    background-color: $primary;
    border-color: $primary;

    &:focus, &:active {
      background-color: darken($primary, 5%);
      box-shadow: 0 0 0 0.2rem transparentize($primary, 0.5)!important;
      border-color: darken($primary, 5%);
    }
  }
}
.quickAdd.bg-success {
  background-color: $primary!important;
  border-color: $primary;
  &:focus, &:active {
    background-color: darken($primary, 5%);
    box-shadow: 0 0 0 0.2rem transparentize($primary, 0.5)!important;
    border-color: darken($primary, 5%);
  }
}
