nav.navbar {
  $textColor: lighten($navBar, 70%);
  $hoverTextColor: lighten($navBar, 40%);
  &.navbar-dark {
    background-color: $black !important;
  }

  #menuButton {
    color: $textColor;

    &:hover {
      color: $hoverTextColor!important
    }
  }

  .userBar {
    background-color: darken($navBar, 2.5%) !important;

    .topItem {
      color: $textColor;

      &:hover {
        color: $hoverTextColor!important
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }
}

@media (max-width: 360px) {
  .logo {
    min-width: 85px;
  }
}
