.btn {
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;
}

.quickview, b.clickable {
  color: darken($primary, 5%);
}
