nav.navbar {
  $textColor: lighten($navBar, 70%);
  $hoverTextColor: lighten($navBar, 40%);
  &.navbar-dark {
    background-color: $black !important;
  }

  #menuButton {
    color: $textColor;

    &:hover {
      color: $hoverTextColor !important;
    }
  }

  .userBar {
    background-color: darken($navBar, 2.5%) !important;

    .topItem {
      color: $textColor;

      &:hover {
        color: $hoverTextColor !important;
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }

  .navbar-brand img.logo {
    width: 50px;
  }

  @include media-breakpoint-up(md) {
    .navbar-brand img.logo {
      min-height: 50px;
    }
  }

  a.navbar-brand > a {
    min-width: 100px !important;
  }
}
