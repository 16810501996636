.block-icon {
  background-color: $primary;
}
app-article-groups-browser {
  .list-group-item {
    text-transform: uppercase;

    span {
      text-transform: none;
    }
  }
}
