#content_container.custom {
  @import "./custom";
  @import "./navbar";

  .topItem {
    color: lighten($navBar, 60%) !important;
  }

  &.bf.ANL {
    .navbar-brand:after {
      content: "";
      bottom: -25px;
      right: 40px;
      background: transparent url(/assets/images/black-friday.svg);
      width: 50px;
      height: 48px;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }
  .modal-header {
    background-color: $navBar;
  }
}

#content_container.friendsAndFamily {
  @import "../../custom/FAF/style.scss";
}

div.ANL {
  /* Modals */
  @import "./variables";
  @import "./navbar";
  @import "./modals";
  @import "./buttons";
  @import "./type";
  @import "./footer";
  @import "./alerts";
  @import "./breadcrumbs";

  &:not(.custom) {
    @import "./homepage";
  }
  @import "./treeview";
  @import "./marketplace";
  @import "./kiosk";
  @import "./other";
}
