app-footer {
  background: $mediumGrey url("/assets/images/footer/gho.jpg") no-repeat;
  background-size: cover;
  color: #fff;
  min-height: 400px;
  color: #fff;
  position: relative;

  a {
    color: $primary;
  }

  &:after {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.85);
    pointer-events: none;
    z-index: -1;
  }
}
