@include media-breakpoint-up(sm) {
  .list-group-item {
    .active {
      background-color: $black;
      > .row {
        background-color: $black;
      }
    }
  }
}

.custom-checkbox {
  input[type="checkbox"]:checked + .box:after {
    background-color: $primary;
    border-color: $primary;
  }
}

.dropdown-item:active {
  background-color: $primary;
  color: $white
}
