.zopim[data-test-id="ChatWidgetButton"],
.zopim[data-test-id="ChatWidgetMobileButton"] {
  display: none !important;
}

#chatButton {
  bottom: 70px;

  &.online {
    color: #006033;

    &:after {
      $green: #80e151;
      background-color: $green;
      box-shadow: 0 0 10px $green;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      position: absolute;
      content: "";
    }
  }
}
