nav.navbar {

  &.navbar-dark {
    background-color: $black !important;
  }

  .dropdown-toggle, .topItem {
    color: lighten($navBar, 70%) !important;

    &.iconWithBadge:before {
      color: #FFF;
      background-color: $primary;
    }
  }

  .userBar {
    background-color: lighten($navBar, 10%) !important;

    .dropdown-toggle {
      color: lighten($navBar, 70%) !important;

      &:hover {
        color: #FFF
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }
  .navbar-brand img.logo {
    max-width: 150px !important;
  }
}

nav {
  .breadcrumb {
    a {
      color: $primary;
    }
  }
}


@media (max-width: 360px) {
  .logo {
    min-width: 85px;
  }
}
