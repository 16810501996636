main.wrapper {
  --extraPaddingTop: 0px;
  --paddingTop: 100px;
  padding-top: var(--paddingTop);
  &.block-content {
    filter: blur(20px);
  }
}

.ab-test-b .COM {

  #generalItemCatalog {
    display: none;
  }

  app-catalogue-navbar {
    display: block;
  }

  main.wrapper {
    --extraPaddingTop: 50px;
    --paddingTop: calc(100px + var(--extraPaddingTop));
    padding-top: var(--paddingTop);
  }
}


.companyBg {
  background-color: var(--companyBg);
}

.tooltip-inner {
  color: #FFF!important
}

app-video video {
  min-width: 100%
}


.parachute-container:hover {
  .parachute {
    animation: fallAndSway 2s ease-in-out infinite;
    opacity: 1;
  }
}

@keyframes fallAndSway {
  0% {
      transform: rotate(0deg);
  }
  25% {
      transform: rotate(-10deg);
  }
  50% {
      transform: rotate(10deg);
  }
  75% {
      transform: rotate(-10deg);
  }
  100% {
      transform: rotate(0deg);
  }
}