app-footer {
  background: $anlMediumGrey url("/assets/images/footer/footer-anl.jpg")
    no-repeat center center;
  background-size: cover;
  color: #fff;
  min-height: 200px;

  .logo {
    max-height: 120px;
  }

  a, li a {
    color: lighten($primary, 25%);
  }

  &:after {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    content: "";
    position: absolute;
    background: rgba(13, 35, 61, 0.6);
    pointer-events: none;
    z-index: -1;
  }
}
