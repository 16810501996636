$bodyMarginTop: 0;
$ghostGrey: #2C2C2C;
$lapBlack: #000;
$vartexGrey: #313436;
$anlNav: #1a467b;
$anlLightGrey: #F8FBFC;
$anlMediumGrey: #e4eaf1;
$anlDarkGrey: #E9EFF4;
$wgBrown: #4b463b;
$maxPageWidth: 1720px;
$smallDesktopFontSize: 0.9rem;
$defaultLink: #285B98;
$paddingTop: 100px;
$body-color: #333;
$shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;

$anl-100: #EBF4FF;
$anl-200: #7DACE4;
$anl-300: #3373C0;
$anl-400: #285B98;
$anl-500: #184070;

$green-100: #E5F8F1;
$green-200: #6CB89B;
$green-400: #039960;
$green-300: #045335;

$red-100: #FBEAEC;
$red-200: #EF8585;
$red-300: #A50922;

:root {
  --primaryTextColor: #{$body-color};
  --secondaryTextColor: black;
  --blue: #{$anlNav} !important;
  --navBar: #{$anlNav} !important;
  --primaryBg: #fff;
  --primaryBgTransparent: rgba(255,255,255,0);
  --secondaryBg: #f8f9fa;
  --secondaryBgTransparent: transparentize(#f8f9fa,1);
  --tertiaryBg: #eff0f2;
  --darkBorder: #d4d4d4;
  --navBg: rgb(255 255 255 / 72%);
  --groupDropdownBg: rgba(255, 255, 255, 0.3);
  --overlayBg: rgba(0, 0, 0, 0.2);
  --modalBackdropBg: rgba(0, 0, 0, 0.6);
  --megamenuBackdropBg: rgba(255,255,255,0.85);
  --bs-btn-disabled-border-color: var(--secondaryBg);
  --sideBarPosition: fixed;
  --swiper-navigation-size: 15px;
  --bs-link-color-rgb: #{$defaultLink};
  --swiper-theme-color: black
  --swiper-navigation-top-offset: -25px;
  --bs-warning-rgb: 230,81,1;
  --homepageBg: #1d1d1d;
  --bs-light-rgb: 248, 249, 250;
  --bs-border-radius-xl: 8px;
}

main {
  --companyBg: rgba(0, 0, 0);
}

$navBar: var(--navBar);

@mixin pageOverlay() {
  content: '';
  position: fixed;
  background-color: var(--overlayBg);
  width: 100%;
  height: 100%;
  left: 0 !important;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none
}

@mixin dropdownBoxShadow() {
  box-shadow: 0 0 20px var(--overlayBg);
}



