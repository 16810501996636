@include media-breakpoint-down(sm) {
  app-side-bar {
    button {
      min-height: 30px;
    }

    form.inActive {
      height: 0;
      overflow: hidden;
    }
  }
}
