@import "bootstrap/scss/functions";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/variables";


$primary: #333;
$blue: #285b98;
$secondary: #2b2b2b;
$gray-100: #f8f9fa;
$light: #fff;
$border-radius: 4px;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
$border-radius-xl: $border-radius;
$table-accent-bg: $gray-100;
$nav-tabs-border-color: var(--secondaryBg);
$table-border-color: var(--secondaryBg);
$font-size-base: 0.95rem;
$alert-border-width: 0;
$modal-content-border-width: 0;
$nav-tabs-border-color: $primary;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: white;
$modal-content-border-radius: $border-radius * 2;
$modal-content-box-shadow-sm-up: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
$modal-sm: 400px;
$nav-pills-link-active-bg: black;
$form-check-input-checked-bg-color: $green;
$form-check-input-checked-border-color: $green;
$list-group-active-bg: $blue;
$list-group-active-border-color: $blue;

$theme-colors: (
  "primary":    $blue,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1450px
);

:root {
  --border-radius: $border-radius;
  --bs-nav-link-font-size: 15px;
  --bs-link-color: $blue;
  --bs-primary-rgb: $blue;
  --bs-btn-hover-bg: $blue;
  --gray-dark: #2b2b2b;
  --bs-accordion-bg: transparent;
}
