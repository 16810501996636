/* You can add global styles to this file, and also import other style files */
@import "@sweetalert2/theme-dark/dark.scss";

.swal2-container {
  z-index: 1230;
  img {
    max-height: 40px;
  }
  &.toastContainer {
    margin-bottom: 30px;
  }
  .swal2-popup {
    border-radius: $border-radius;
    width: auto;
    .swal2-toast {
      box-shadow: none;
    }
  }
}

.swal2-actions {
  margin-top: 5px;

  &.actions-bg {
    background-color: $gray-200;
    justify-content: space-between;
    padding: 5px;
  }
}





