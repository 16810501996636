
$navBar: rgb(29, 29, 29);
$primary: var(--primaryTextColor);
$lightGrey: var(--secondaryBg);
$mediumGrey: var(--tertiaryBg);
$darkGrey: #E9EFF4;
$black: #343a40;

@import "./kiosk";
@import "./other";

div.TUN {
  nav.navbar {
    $textColor: lighten($navBar, 70%);
    $hoverTextColor: lighten($navBar, 40%);

    &.navbar-dark {
      background-color: $black !important;
    }

    #menuButton {
      color: $textColor;

      &:hover {
        color: $hoverTextColor !important
      }
    }

    .userBar {
      background-color: #23282b !important;

      .topItem {
        color: $textColor;

        &:hover {
          color: $hoverTextColor !important
        }
      }

      .dropdown-menu {
        background-color: $navBar !important;
      }
    }

    .navbar-brand img.logo {
      width: 50px;
      min-height: 30px;
    }
  }
  .custom-checkbox {
    input[type="checkbox"]:checked + .box:after {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .dropdown-item:active {
    background-color: $primary;
    color: $white
  }

  // Marketplace
  .supply-and-demand {
    background-color: $lightGrey;
  }
  .create-button {
    background-color: $primary;
  }
  .nav-link {
    &.active {
      color: $navBar;
      background-color: $lightGrey !important;
    }
  }
  .nav-link {
    color: $primary;
  }
  .search-button {
    background-color: $primary;
  }
  .reset-link {
    color: $primary;
  }
  .title {
    color: $navBar;
  }
  .subtitle {
    color: $primary;
  }
}
