@import "bootstrap/scss/variables";
@import "src/scss/custom_variables";



nav.navbar {
  transition: background linear 300ms;

  .iconWithBadge {
    position: relative;

    &:before {
      transition: all 300ms ease-in;
      content: attr(data-count);
      position: absolute;
      top: 0px;
      right: 5px;
      width: 18px;
      background-color: $green;
      border-radius: 50%;
      pointer-events: none;
      font-size: 12px;
      z-index: 1;
    }
  }

  &.fixed-bottom {
    border-top: solid 1px var(--tertiaryBg);
    z-index: 1029;
  }

  .dropdownContainer {
    .topItem + dropdown-menu {
      .dropdown-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.navbar-dark {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    .material-icons,
    .img-container img {
      &.light {
        color: #fff;
        margin-right: 5px;
        margin-left: 5px;
        &:hover {
          opacity: 1;
        }
      }
    }

    input[type="text"] {
      background-color: rgba(255, 255, 255, 0.2);
      border: none;
      color: #fff;
    }

    i.material-icons {
      &:hover {
        opacity: 1 !important;
      }
    }

    &.gc_GHO {
      background-color: $ghostGrey !important;
    }

    &.gc_TUN {
      .logo {
        min-height: 20px;
        min-width: 132px;
      }
    }

    &.gc_VAR {
      background-color: $vartexGrey !important;

      .logo {
        width: 200px;
      }
    }
  }

  @media (max-width: 980px) {
    .topItem {
      font-size: 0.65rem;
    }
  }
}

ngb-tabset {
  a.nav-link {
    &.active {
      background-color: $gray-100 !important;
      border: none;
      border-bottom: solid 1px darken($gray-100, 20%) !important;
    }
  }
}
.counters {
  justify-content: flex-end;
}
@include media-breakpoint-down(md) {

  .navbar-brand {
    img.logo {
      max-width: 100px !important;
    }
  }

  .dropdownContainer:last-child button {
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include media-breakpoint-down(lg) {
  .navbar-brand {
    img.logo {
      max-width: 100px !important;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: $maxPageWidth) {
  .counters {
    padding-right: 0 !important;
    min-width: 240px;
  }

  #navbarSupportedContent {
    padding-left: 0 !important;
  }
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

app-catalogue-navbar {
  --catButtonHoverColor: white;
  --catButtonHoverBg: #{$body-color};
}
