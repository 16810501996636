@import "_bootstrap_variables";
@import "bootstrap/scss/bootstrap";
@import "_custom_variables";
@import "_mixins";
@import "_general";
@import "_navbar";
@import "_modal";
@import "_buttons";
@import "_status";
@import "_list";
@import "_type";
@import "_chat";
@import "_forms";
@import "_alerts";
@import "_homepage";
@import "_dropdown";
@import "_marketplace";

// Mobile
@import "mobile/general";
@import "mobile/navbar";
@import "mobile/pagination";
@import "mobile/carousel";
@import "mobile/filters";
@import "mobile/modals";

// Company styles
@import "companyStyles/ANL/style";
@import "companyStyles/LAP/style";
@import "companyStyles/GHO/style";
@import "companyStyles/COM/style";
@import "companyStyles/WG/style";
@import "companyStyles/VAR/style";
@import "companyStyles/TUN/style";
@import "companyStyles/RAL/style";

//@import "~material-icons-font/material-icons-font.css";
@import "dark_theme";
@import "~jsoneditor/dist/jsoneditor.css";


html {
  scroll-behavior: auto!important;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  transition: font-size 300ms linear;

  app-root {
    display: block;
    max-width: 100%;
    overflow-x: hidden;
  }

  &.helpdeskMode {
    padding-top: 20px;
    #header.fixed-top {
      margin-top: 20px;
    }
    @include media-breakpoint-down(md) {
      #header.fixed-top {
        margin-top: 40px;
      }
    }
  }

  &.accMode {
    font-size: 1.3rem !important;

    .btn {
      font-size: 1.3rem !important;
    }
  }

  &.modal-open {
    // height: 100vh;
    -webkit-overflow-scrolling: touch;
  }
}

.skeleton-loader
{
  opacity: .6;
}

summary {
  list-style-type: none;
}

details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

.visually-hidden {
  display: none;
}

.white-text {
  color: white;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

/* Add application styles & imports to this file! */
$transition_time: 0.2s;

.transition {
  transition: all $transition_time ease-in-out;
}

h1 {
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  &.styled {
    position: relative;

    &:after {
      content: "";
      max-width: 500px;
      width: 100%;
      background-color: darken($gray-100, 5%);
      height: 3px;
      position: absolute;
      bottom: 15px;
      left: 0;
    }
  }
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  &.subHeader {
    color: #666;
  }
}

.clickable {
  cursor: pointer;

  &.withOpacity {
    &:hover {
      opacity: 0.7;
    }
  }
}

.custom-scrolltop {
  span {
    &:after {
      font-family: "Material Icons";
      content: "keyboard_arrow_up";
      width: 16px;
      height: 16px;
    }
  }
  &.global-to-top {
    bottom: 80px;
  }
}

.img-fluid {
  &.card-img-top {
    max-height: 200px;
  }

  width: auto !important;
  margin: 0 auto;
  display: inherit;
}

.custom-tooltip .tooltip-inner {
  background-color: var(--secondaryBg);
  color: #000;
  font-size: 125%;
  border: solid 1px #666;
}

.custom-tooltip .arrow::before {
  border-right-color: #666;
}

.black-overlay {
  position: fixed !important;
}

.wrapper:not(.chatMessage) {
  min-height: 90vh;
}

.container {
  max-width: $maxPageWidth;
}

.bg-gray {
  background-color: var(--secondaryBg);
}

.pagination {
  margin-bottom: 0 !important;
}

.loader {
  background-color: $gray-100 !important;
}

.table > :not(caption) > * > *  {
  box-shadow: none;
}

.quickview {
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    &:after {
      position: absolute;
      font-family: "Material Icons";
      content: "info";
    }
  }
}

app-bread-crumbs {
  .container {
    padding-left: 15px;
  }
}

.ngx-gallery-arrow {
  color: #000 !important;
}

ngx-gallery-preview {
  .ngx-gallery-arrow {
    color: #fff !important;
  }
}

.ngx-gallery-preview-img.ngx-gallery-active {
  box-shadow: 2px 10px 20px 10px rgba(0, 0, 0, 0.25);
}

.ngx-gallery-preview-top {
  padding-top: 50px;
}

ngx-gallery-action {
  margin-right: 10px;
}

.ngx-gallery-image,
.ngx-gallery-thumbnail {
  background-size: contain !important;
  background-color: #fff;
}

.ngx-gallery-arrow-wrapper {
  z-index: 98 !important;
}

.ngx-gallery-preview-img-wrapper {
  img {
    background-color: #fff;
  }
}

.ngx-gallery-active {
  z-index: 98 !important;
}

ngx-gallery-preview {
  &.ngx-gallery-active {
    z-index: 10000 !important;
  }
}

ngx-gallery-thumbnails {
  margin-top: 40px !important;
  min-height: 50px;

  .ngx-gallery-thumbnails {
    display: flex;
    align-items: center;
    justify-content: center;

    .ngx-gallery-thumbnail {
      border: solid 1px #eee;
      background-size: 90% !important;
      position: static !important;
      margin: 0 5px;

      &.ngx-gallery-active {
        border-color: var(--blue);
      }
    }
  }
}

.modal-lg {
  max-width: $maxPageWidth;
}

.medium {
  .modal-lg {
    max-width: $maxPageWidth / 2;
  }
}

.badge {
  font-weight: normal;
}

.card-body {
  border-radius: $border-radius;
}

#loading-bar-spinner {
  left: calc(50% - 30px) !important;
  top: calc(50% - 30px) !important;
}

.popoverTrigger {
  cursor: pointer;
}

$scrollBg: #9b9b9b;

.customScrollbar {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollBg;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: $scrollBg;
  }
}

.spinner-loader {
  position: fixed;
  top: 50vh;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 9999;

  &.spinner-loader-lg {
    transform: scale(3);
  }
}

pdf-viewer {
  min-height: 60vh;
}

.swiper-wrapper {
  display: none;
}

[hidden] {
  display: none !important;
}

pdf-viewer canvas {
  max-width: 100%;
}