.custom-control-input:checked~.custom-control-label::before {
  background-color: $green;
  border-color: darken($green, 10%);
}

.form-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: $green;
  border-color: darken($green, 10%);
  cursor: not-allowed;
  opacity: 0.7;
}

.form-switch .custom-control-input:disabled:checked~.custom-control-label::after {
  cursor: not-allowed;
}

select.form-control{
  appearance: auto;
  padding: 6.5px;
}

form {
  &.validated, &.validatedChecked {

    input,
    div,
    select,
    textarea {
      &.ng-invalid {
        color: $red;
        border-color: $red;

        &::placeholder {
          color: lighten($red, 20%);
        }

        input.form-control-file {
          color: $red;
        }
      }
    }
  }
  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    .form-check {
      line-height: 25px;
      .form-check-input {
        margin-top: 4px!important;
      }
    }
  }
}

.formContainer {
  form {
    background-color: var(--secondaryBg);

    input {
      &.form-control {
        min-width: 100px;
      }
    }
  }
}

.form-group {
  border-radius: $border-radius;
}
