div.VAR {
  /* Modals */
  @import "./variables";
  @import "./buttons";
  @import "./navbar";
  @import "./modals";
  @import "./treeview";
  @import "./footer";
  @import "./homepage";
  @import "./marketplace";
  @import "./kiosk";
  @import "./other";
}
