app-supply-and-demand-page {
  .supply-and-demand {
    background-color:  var(--secondaryBg);
  }
  .create-button {
    background-color: $primary;
  }
  .nav-link {
    &.active {
      color: $navBar;
      background-color: $lightGrey !important;
    }
  }
  .nav-link {
    color: $primary;
  }
  .search-button {
    background-color: $primary;
  }
  .reset-link {
    color: $primary;
  }
  .title {
    color: $navBar;
  }
  .subtitle {
    color: $primary;
  }
}
