.btn {
  &.btn-primary {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 10%);
  }
}

.btn-success:not(.articleRowLabel) {
  background: rgb(16,98,60);
  background: linear-gradient(132deg, rgba(16,98,60,1) 0%, rgba(25,135,84,1) 100%);

  &:hover {
    background: linear-gradient(132deg, rgb(16, 98, 40) 0%, rgb(43 162 107) 100%)
  }
}

.btn-primary:not(.articleRowLabel){
  background: rgb(17,75,111);
  background: linear-gradient(132deg, rgba(17,75,111,1) 0%, rgba(7,47,71,1) 100%);

  &:hover {
    background: linear-gradient(132deg, rgba(12,61,91,1) 0%, rgb(10, 59, 90) 100%);
  }
}

.btn-outline-primary:not(:hover) {
  border-color: var(--primaryTextColor);
  color: var(--primaryTextColor);
}


