$black: #1d1d1d;
$dark-gray: #3e3e3e;
$gray: #575b60;
$navBar: #0c3d5b;
$navBarText: white;
$userBar: darken($navBar, 5%);
$primary: #285b98;
$primary-light: #2e7bb9;
$anlLightGrey: #f8fbfc;
$anlMediumGrey: #e4eaf1;
$anlDarkGrey: #e9eff4;
$green: #42b292;

.modal-header {
  background-color: $navBar !important;
}

nav.navbar {
  &.navbar-dark {
    background-color: $navBar !important;
  }

  .userBar {
    background-color: darken($navBar, 2.5%) !important;
  }
  .dropdown-menu {
    background-color: $navBar !important;
    color: $navBarText;
    .dropdown-item {
      color: $navBarText;
      &:hover, i{
        color: black
      }
    }
  }

  ngb-typeahead-window {
    transform: translateY(40px)!important;
    img {
      background-color: white;
      mix-blend-mode: initial
    }
    button {

      background-color: $navBar!important;
      &.active {
        background-color: darken($navBar, 5%)!important;
      }
      small {
        color: white!important
      }
      &:hover{
        * {
          color: rgba(255,255,255,0.8)!important
        }
      }
      .mb-1.btn.btn-dark {
        display: none!important;
      }
    }
  }
}

.iconWithBadge:before {
  background-color: $green;
}
