app-supply-and-demand-page {
  .create-button {
    background-color: $primary;
  }
  .supply-and-demand {
    .nav-link {
      &.active {
        color: var(--primaryTextColor);
        background-color: $anlLightGrey !important;
        text-decoration: underline;
      }
    }
    .nav-link {
      color: $primary;
    }
    .search-button {
      background-color: $primary;
    }
    .reset-link {
      color: $primary;
    }
    .title {
      color: $anl-500;
    }
    .subtitle {
      color: $primary;
    }
  }
}
