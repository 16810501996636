@media only screen and (max-height: 1000px) {

  html,
  html body,
  .dropdown-menu {
    font-size: $smallDesktopFontSize;
  }

}

@media only screen and (max-width: 768px) and (max-height: 1024px) {

  html,
  html body,
  .dropdown-menu {
    font-size: $smallDesktopFontSize;
  }
}

body:not(.accMode) {
  app-financial {
    font-size: 1rem;
  }
}

.big-icon {
  font-size: 100px;
  color: #EEE;
}
