@include media-breakpoint-down(sm) {
  .modal {
      z-index: 1400;
    &.sticky-right {
      min-width: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}
