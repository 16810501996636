nav.navbar {



  &.navbar-dark {
    background-color: $black !important;
  }

  .navbar-brand {
    &:after {
      left: 20px;
      right: initial!important;
    }
  }

  .browse-groups {
    button:not(.topItem) {
      text-transform: uppercase;
    }
  }

  .dropdown-toggle, .topItem {
    &:not(.catalogItem) {
      color: lighten($navBar, 70%) !important;
    }

    &.iconWithBadge:before {
      color: #FFF;
      background-color: $primary;
    }
  }

  .userBar {
    background-color: lighten($navBar, 10%) !important;

    .dropdown-toggle {
      color: lighten($navBar, 70%) !important;

      &:hover {
        color: #FFF
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }
  .navbar-brand img.logo {
    max-width: 130px !important;
  }
}

@media (max-width: 360px) {
  .logo {
    min-width: 125px;
  }
}


@media (max-width: 1339px) {
  .topItem {
    font-size: 0.85rem;
  }
}

@media (max-width: 1120px) {
  .topItem {
    font-size: 0.75rem;
  }
}

@media (min-width: 1340px) {
  .topItem {
    font-size: 0.9rem;
  }
}

