body {
  background-color: var(--primaryBg);
  color: var(--primaryTextColor)
}

.dark-theme {
  color-scheme: dark;
  --primaryBg:  rgb(32 38 46);
  --primaryBgTransparent:  rgba(32 38 46 0);
  --secondaryBg: rgb(36, 43, 52);
  --secondaryBgTransparent: rgba(42, 50, 61, 0);
  --tertiaryBg: rgb(27, 32, 39);
  --lightBg: rgb(69 79 91);
  --navBg: rgb(0 0 0 / 72%);
  --primaryTextColor: rgba(255, 255, 255, 0.87);
  --secondaryTextColor: rgba(255, 255, 255, 0.768);
  --blue: #b6d7ff;
  --darkBorder: #373737;
  --bs-secondary-rgb: var(--secondaryTextColor);
  --overlayBg: rgba(32 38 46 0.7);
  --groupDropdownBg: rgb(55 62 72);
  --modalBackdropBg: rgba(91, 96, 102, 0.95);
  --megamenuBackdropBg: rgba(32, 38, 46, 0.95);
  --bs-body-bg: var(--primaryBg);
  --homepageBg: var(--primaryBg);
  --gray-dark:  rgb(55, 61, 68);
  --bs-link-color-rgb: #78aaff;
  --bs-body-color: var(var(--primaryTextColor));

  app-article-card {
    color: var(--primaryTextColor);
    .card-details-overlay {
      outline: solid 1px var(--lightBg)
    }
  }

  app-retail-price, app-lease-price, app-gross-price {
    color: var(--primaryTextColor)!important
  }

  .card-body small, .container:not(.compact) h2 {
    color: var(--secondaryTextColor)!important
  }

  app-retail-price, app-lease-price, app-gross-price {
    color: var(--primaryTextColor)!important
  }

  .card-body small, .container:not(.compact) h2 {
    color: var(--secondaryTextColor)!important
  }

  .btn {
    --bs-btn-color: #FFF
  }


  .btn-light {
    --bs-btn-bg: var(--primaryBg);
  }

  .btn-dark {
    --bs-btn-bg: rgb(24, 29, 35);
    --bs-btn-border-color: rgb(24, 29, 35);
  }

  .alert-warning {
    --bs-alert-color: #c4aa5e;
    --bs-alert-bg: #393012;
  }

  .alert-light {
    --bs-alert-color: var(--primaryTextColor);
    --bs-alert-bg: var(--lightBg);
  }

  ngb-datepicker-month, ngb-datepicker-navigation-select select, .ngb-dp-header {
    background-color: white;
    filter: invert(1);
    [ngbDatepickerDayView],.ngb-dp-navigation-chevron {
      color: black
    }
  }

  .skeleton-loader {
    filter: invert(1);
  }

  .articlePreImg {
    filter: invert(100%);
  }

  .dashboard-header {
    background: none;
  }

  --bs-border-color: var(--primaryBg);
  --bs-dark-rgb: 0, 0, 0;
  --bs-light-rgb: 36, 43, 52;
  --bs-white-rgb: 32, 38, 46;

  //--bs-bg-opacity: 1;

  .modal {
    --bs-modal-bg: var(--primaryBg);
  }

  .modal-backdrop {
    --bs-backdrop-opacity: 0.8;
  }

  .table {
    --bs-table-color: var(--primaryTextColor);
    --bs-table-hover-color: var(--secondaryTextColor);
    --bs-table-striped-color: var(--secondaryTextColor);
  }

  .list-group {
    --bs-list-group-color: var(--primaryTextColor);
    --bs-list-group-border-color: var(--tertiaryBg);
    --bs-list-group-bg: var(--primaryBg);
    --bs-list-group-action-hover-bg: var(--secondaryBg);
  }

  .pagination {
    --bs-pagination-border-color: var(--primaryBg);
    --bs-pagination-bg: var(--primaryBg);
    --bs-pagination-disabled-bg: var(--secondaryBg);
    --bs-pagination-disabled-border-color: var(--secondaryBg);
    --bs-pagination-disabled-color: var(--secondaryTextColor);
  }

  .card {
    --bs-card-bg: var(--primaryBg);
    --bs-card-color: var(--primaryTextColor);
    --bs-card-cap-color: var(--primaryTextColor)
  }

  .breadcrumb {
    --bs-breadcrumb-item-active-color: var(--secondaryTextColor);
    --bs-breadcrumb-divider-color: var(--secondaryTextColor);
  }

  .form-check-input {
    --bs-form-check-bg: var(--lightBg);
  }

  // specific dark theme
  .article-image {
    border-radius: 15px;
  }

  .card-title > span,.articleHeader {
    color: white;
  }

  .text-bg-light {
    color: var(--primaryTextColor)!important
  }

  .text-dark {
    color: var(--primaryTextColor)
  }
  .status_0,.status_1,.status_2 {
    &:not(.selected) {
      color: rgb(19, 168, 31)
    }
  }
  .status_0,.status_1,.status_2 {
    &:hover {
      color: rgb(214, 249, 217)
    }
  }

  .status_3:not(.selected) {
    color: rgb(252, 157, 69)
  }
  .status_3:not(.selected):hover {
    color: rgb(255, 255, 255)
  }
  .status_4,.status_5 {
    &:not(.selected) {
      color: rgb(246 65 65)
    }
  }
  --status012BgColor: rgb(20 33 20);
  --status3Color: rgb(27 19 15);
  --statusTextColor: var(--primaryTextColor);
  --defaultStatusBorder: transparent;
  --status012Color: rgb(200 255 200);

  .form-control {
    background-color: rgba(var(--bs-white-rgb), 1) !important;
    border-color: var(--lightBg);
    color: var(--primaryTextColor)
  }

  app-logos-container {
    img {
      background-color: #FFF!important;
      padding: 10px!important;
    }
  }

  ngb-typeahead-window img {
    mix-blend-mode: initial!important;
  }

  app-nett-price {
    color: #FFF;
  }

  .ANL {
    #footer {
      filter: grayscale(0.7)
    }
  }
  #footer {
    .logo {
      filter: drop-shadow(0px 0px 54px white) drop-shadow(1px 2px 0px black)
    }
  }

  .RAL,.VAR {
    app-footer {
      .logo {
        filter: invert(1)!important
      }
    }
  }

  .popover {
    --bs-popover-body-color: var(--primaryTextColor);
    --bs-popover-bg: var(--primaryBg);
    --bs-popover-header-bg: var(--secondaryBg);
  }

  .dropdown-menu {
    --bs-dropdown-color: var(--primaryTextColor);
    --bs-dropdown-bg: var(--primaryBg);
    --bs-dropdown-link-color: var(--primaryTextColor);
  }
}

