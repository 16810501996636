@import "src/scss/bootstrap_variables";


@include media-breakpoint-down(sm) {
  html {
    overflow-x: hidden;
  }

  body {
    &:not(.helpdeskMode) {
      padding-top: 0;
    }

    &.helpdeskMode {
      padding-top: 40px !important;
      nav#header {
        margin-top: 40px;
      }
    }
  }

  .swal2-no-backdrop .swal2-container.swal2-bottom {
    min-width: 100%;
    left: 10px;
    right: 10px;
  }
  main.wrapper {
    padding-top: 84px;
  }

  body:has(app-basket-preview .dropdown-menu:visible) {
    .navbar.fixed-bottom {
      display: none;
    }
  }
}
app-kiosk {
  display: block;
  margin-top: -#{$paddingTop};
}
