@import "src/scss/bootstrap_variables";

@include media-breakpoint-down(sm) {
  nav {
    app-quick-settings {
      max-width: 50px;
      i {
        transform: scale(0.8);
      }
      .popoverTrigger {
        margin-top: -10px;
      }
    }

    .breadcrumb {
      min-width: 100vw;
      width: max-content;
      white-space: nowrap;
      font-size: 16px;
      padding-bottom: 5px;

      &:before {
        max-height: 55px;
      }
    }
    .counters {
      position: absolute;
      top: 0;
      right: 10px;
      width: auto
    }
    app-logo {
      min-height: 50px;
    }
    app-logo,
    app-alert-counter,
    app-basket-preview,
    app-pending-requests,
    app-favorites-button,
    app-show-compare-button,
    app-dropshipment-counter {
      .btn {
        padding-left: 2.5px;
        padding-right: 2.5px;
      }
    }

    app-search-bar {
      margin-right: auto;
    }

    @include media-breakpoint-up(md) {
      app-logo,
      app-alert-counter,
      app-basket-preview,
      app-pending-requests,
      app-show-compare-button,
      app-favorites-button,
      app-dropshipment-counter {
        top: 20px;
      }
      .counters {
        top: 20px;
      }
    }


    app-alert-counter {
      div.dropdown-menu.show {
        position: fixed;
        min-width: 100%;
        width: 100%;
        top: 100px;
      }
    }

    ngb-typeahead-window {
      left: 0 !important;
      width: calc(100vw - 15px);
    }

    .userBar {
      div:first-child {
        order: 1;
      }
    }

    .navbar-brand {
      top: 4px;
      left: 60px;

      &:after {
        transform: scale(0.7);
        right: 0 !important;
      }

      .logo {
        max-height: 30px;
      }
    }

    app-mega-menu {
      margin-top: 5px;
      @include media-breakpoint-only(md) {
        margin-left: 15px;
      }
    }

    ngb-tooltip-window {
      display: none !important;
    }

    ngb-popover-window {
      top: 20vh;
      left: 0 !important;
      right: 0;
      margin: 0 auto;

      .arrow {
        visibility: hidden;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar-brand {
    position: absolute !important;
    .logo {
      max-height: 30px !important;
    }
  }
  .breadcrumb {
    margin-top: 15px;
    padding-bottom: 10px !important;
  }

  app-favorites-button {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .iconWithBadge:before {
    transform: scale(0.8);
  }
}

@include media-breakpoint-down(lg) {
  .menu-container {
    max-width: 60px;
  }
}
