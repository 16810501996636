.kiosk-menu {
  app-kiosk-logo {
    background-color: $navBar !important;
  }
  ul > li > a {
    color: $navBar !important;
    &:visited {
      color: $navBar !important;
    }
    &.active {
      &:before {
        background-color: $navBar !important;
      }
    }
    span.amount-indicator {
      background: $green !important;
    }
  }
  app-kiosk-search-bar {
    background-color: $navBar !important;
    input {
      background-color: $primary !important;
    }
  }
}

.kiosk-landing {
  .kiosk-primary-button {
    color: $navBar !important;
  }
}

app-kiosk-search-filter {
  background: $navBar !important;
  .ngx-slider .ngx-slider-pointer {
    background-color: $primary !important;
  }
}

.searchPagination .pagination .active a {
  background-color: $primary !important;
  border-color: $primary !important;
}

:host ::ng-deep {
  .compare-articles-modal {
    .articles-modal-header {
      background-color: $primary !important;
    }
    .article-list-caption {
      color: $navBar !important;
    }
    .delete-compare-list-button {
      color: $primary !important;
    }
    .compare-button {
      background-color: $navBar !important;
    }
    .article-list-title {
      color: $navBar !important;
    }
  }
}

app-kiosk-article-details {
  button.back-button {
    border-color: $primary !important;
    span {
      color: $primary !important;
    }
  }
  button.add-to-compare-button {
    border-color: $primary !important;
    color: $primary !important;
    &.active {
      border-color: $primary !important;
      background: $primary !important;
      color: white !important;
    }
  }
  .leaflet-button {
    color: $primary !important;
  }
  button.add-to-basket-button {
    background-color: $green !important;
  }
}

.kiosk-breadcrumb {
  color: $navBar !important;
}

app-kiosk-grouped-articles {
  h2 {
    color: $navBar !important;
  }
}
