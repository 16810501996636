.supply-and-demand {
  .create-button {
    background-color: $primary;
  }
  .nav-link {
    &.active {
      color: $anl-500;
      background-color: var(--secondaryBg) !important;
    }
  }
  .nav-link {
    color: $primary;
  }
  .search-button {
    background-color: $primary;
  }
  .reset-link {
    color: $primary;
  }
  .title {
    color: $anl-500;
  }
  .subtitle {
    color: $primary;
  }
}
