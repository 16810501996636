.block-icon {
  background-color: $primary;
}

.links-homepage > a {
  color: $primary;
}
app-home-page {
  .bg-full, .banner-container {
    background: rgb(6,42,64);
    background: linear-gradient(132deg, rgba(6,42,64,1) 0%, rgba(12,61,91,1) 100%);
  }
  .list-container {
    background: linear-gradient(
      180deg,
      $navBar 0%,
      rgba(11, 52, 101, 0) 95%
    ) !important;

    .list-group-item {
      border-color: $navBar;
    }
  }
}
.compareCount {
  background-color: $primary;
}
