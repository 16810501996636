ngb-modal-window {
  &.hidden {
    .modal-body {
      visibility: hidden;
    }
  }
  .modal-dialog {
    margin-bottom: 200px;
    @media only screen and (max-height: 700px) {
      margin-top: 0;
    }
  }
  &.medium {
    .modal-dialog {
      width: 700px;
      max-width: calc(100% - (0.5rem * 2));
    }
  }
  &.large {
    .modal-dialog {
      width:  1280px;
      max-width: calc(100% - (0.5rem * 2));
    }
  }
  &.addToBasketModal,&.searchModal {
    &.modal {
      overflow-y: hidden;
    }
  }
  &.modal {
    z-index: 1060;
  }
}

@keyframes modal-b-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include media-breakpoint-up(md) {
  .modal {
    padding: 10px;
  }
}

.modal-content {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 20%),
    0 10px 10px -5px rgb(0 0 0 / 14%);
}

.modal-backdrop {
  background-color: var(--modalBackdropBg);
}

.modal-dialog {
  animation: ease-in-out 0.3s modal-fade;
  transition: all 0.3s ease-in-out;
}

.modal-dialog:not(.modal-dialog-centered) {
  margin-top: 40px !important;
}

.modal-content {
  .close {
    z-index: 9999;
  }
}

.modal-header {
  background-color: #313436;
  color: #fff;

  .btn-close {
    filter: invert(100%)
  }
}

.sticky-right {
  left: initial !important;
  right: 0;
  max-width: 40vw;
  min-width: 800px;
  box-shadow: -20px 0 30px rgba(0, 0, 0, 0.1);

  .modal-dialog {
    margin-top: 40px !important;
  }
  .centerContainer + .centerContainer {
    display: block;
    min-width: 100%;
    app-article-links {
      float: left !important;
    }
  }
}

.fixed-modal {
  .modal-dialog {
    margin-top: 0px;
  }
}

#modalContainer {
  .swal2-container {
    z-index: 2230;
  }
}
