app-preorder-alert {
  ngb-alert {
    h4 {
      color: $primary
    }
  }
}

.swal2-popup {
  background-color: rgba(6, 36, 71, 1);
  opacity: 0.95;
}
