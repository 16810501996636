nav.navbar {
  $textColor: lighten($navBar, 70%);
  $hoverTextColor: lighten($navBar, 40%);
  &.navbar-dark {
    background-color: $black !important;
  }

  #menuButton {
    color: $textColor;

    &:hover {
      color: $hoverTextColor!important
    }
  }

  .userBar {
    background-color: darken($navBar, 2.5%) !important;

    .topItem {
      color: $textColor;

      &:hover {
        color: $hoverTextColor!important
      }
    }

    .dropdown-menu {
      background-color: $navBar !important;
    }
  }

  #preorderButton {
    color: $primary;
  }

  .navbar-brand img.logo {
    max-width: 140px !important;
  }
}
