app-footer {
  background-color: $mediumGrey;
  color: #fff;
  min-height: 400px;
  position: relative;
  a {
    color: rgba(255,255,255,0.7);
  }

  .logo {
    max-width: 200px;
  }

  &:after {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    pointer-events: none;
    z-index: -1;
  }
}
